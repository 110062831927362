import React, {useState, useEffect, useContext} from 'react';
import {Context} from '../../../AppContext';
import {Spin} from 'antd';
import styled from 'styled-components';
import Image, {ImageWrapper} from '../../../Components/Image';
import {Text, Divider} from '../../../Widgets';
import Tag from '../../../Components/ProductTag';
const slugify = require('uslug');
const appConfig = require('../../../data.json');

function ProductItem(props) {
  const app = useContext(Context);
  const [freeShipping, setFreeShip] = useState(false);
  const {product, onClick} = props;

  useEffect(() => {
    if (product) {
      try {
        const spec = app.actions.getProductFromSpec({
          productName: product.name,
        });
        setFreeShip(spec && spec.free_shipping);
      } catch (err) {}
    }
  }, [app.actions, product]);

  if (!product) {
    return null;
  }

  return (
    <Wrapper onClick={onClick}>
      <Image
        background
        src={product.image}
        style={{
          backgroundColor: '#e0e0e0',
          margin: 0,
          width: 176,
          height: 176,
        }}
        alt={'product item -' + product.name}
      />

      <Content>
        <Text
          size="xs"
          style={{
            textAlign: 'left',
            marginBottom: 6,
          }}>
          {product.name}
        </Text>

        {/* price from product model */}
        {product.original_price !== product.price && (
          // original price
          <Text
            size="xs"
            color="#aaa"
            style={{
              textDecoration: 'line-through',
              textAlign: 'center',
            }}>
            NT: {product.original_price}
          </Text>
        )}
        {/* price */}
        <Text
          size="xs"
          color={appConfig.colors.main}
          style={{
            textAlign: 'left',
            fontWeight: '700',
          }}>
          NT: ${product.price}起
        </Text>
      </Content>
      <Tag product={product} freeShipping={freeShipping} onItem={true} />
    </Wrapper>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  width: auto;
  height: 176px;
  margin-bottom: 20px;
  background-color: white;
  /* border: 1px solid #ddd; */
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 3px 10px -3px #6665;
  }
  ${(props) => props.css}
`;

export default function RelatedProduct(props) {
  const {id} = props;
  const app = useContext(Context);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (id) {
        let resp = await app.actions.getProduct(id);
        setProduct(resp);
      }
      setLoading(false);
    })();
  }, [id, app.actions]);

  return loading ? (
    <Spin />
  ) : product ? (
    <div>
      <Divider />

      <label
        style={{
          fontWeight: 500,
          flexBasis: 100,
          paddingTop: 6,
          color: appConfig.colors.textSecond,
        }}>
        相關商品
      </label>
      <ProductItem
        product={product}
        onClick={() => {
          window.open(`/product/${product.id}`, '_blank');
        }}
      />
    </div>
  ) : null;
}
